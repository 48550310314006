import './App.css'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import AuthProvider, { SignedIn, SignedOut } from './contexts/authContext'

import SharedHeader from './routes/shared/Header'
import LoginHeader from './routes/shared/LoginHeader'
import LoginFooter from './routes/shared/LoginFooter'
import SignIn from './routes/SignIn'
import Applications from './routes/Applications'
import Registerations from './routes/Registerations'
import UpdateApplication from './routes/UpdateApplication'
import UpdateRegisteration from './routes/UpdateRegisteration'
import Apply from './routes/Apply'
import FormSwitcher from './FormSwitcher'
import { ConfigProvider } from 'antd'

function App() {
    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Montserrat',
                    },
                }}>
                <BrowserRouter>
                    <AuthProvider>
                        <SignedIn>
                            <Routes>
                                <Route
                                    path="applications"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <Applications />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route
                                    path="registerations"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <Registerations />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                {/* <Route
                                    path="apply"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <Apply />
                                            <LoginFooter />
                                        </>
                                    }
                                /> */}
                                <Route
                                    path="update/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <FormSwitcher />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                {/* <Route
                                    path="update-application/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <UpdateApplication />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route
                                    path="update-registeration/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <UpdateRegisteration />
                                            <LoginFooter />
                                        </>
                                    }
                                /> */}
                                {/* <Route path="application/:id" element={<Apply />} /> */}
                                <Route path="*" element={<Navigate to="/applications" />} />
                            </Routes>
                        </SignedIn>
                        <SignedOut>
                            <Routes>
                                <Route
                                    path="/signin"
                                    element={
                                        <>
                                            <LoginHeader />
                                            <SignIn />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route path="*" element={<Navigate to="/signin" />} />
                            </Routes>
                        </SignedOut>
                    </AuthProvider>
                </BrowserRouter>
            </ConfigProvider>
        </>
    )
}

export default App
