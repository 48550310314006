import React, { useEffect } from 'react'

import * as cognito from '../libs/cognito'

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuthContext {
    sessionInfo?: { email?: string; sub?: string; accessToken?: string; refreshToken?: string; idToken?: string }
    authStatus?: AuthStatus
    signIn?: any
    signUp?: any
    signOut?: any
    verifyCode?: any
    getSession?: any
    sendCode?: any
    forgotPassword?: any
    changePassword?: any
}

const defaultState: IAuthContext = {
    sessionInfo: {},
    authStatus: AuthStatus.Loading,
}

type Props = {
    children?: React.ReactNode
}

export const AuthContext = React.createContext<IAuthContext>(defaultState)

export const SignedIn = ({ children }: Props) => {
    const { authStatus }: IAuthContext = React.useContext(AuthContext)
    return authStatus === AuthStatus.SignedIn ? <>{children}</> : null
}

export const SignedOut = ({ children }: Props) => {
    const { authStatus }: IAuthContext = React.useContext(AuthContext)
    return authStatus === AuthStatus.SignedOut ? <>{children}</> : null
}

const checkExpiry = () => {
    const idTokenExpiry: number = parseInt(window.localStorage.getItem('idTokenExpiry')!)
    const currentTime = new Date().getTime() / 1000
    // returns true if token has 1 hour to expire
    return idTokenExpiry - currentTime < 3600
}

const AuthProvider = ({ children }: Props) => {
    const [authStatus, setAuthStatus] = React.useState(AuthStatus.Loading)
    const [sessionInfo, setSessionInfo] = React.useState({})

    useEffect(() => {
        async function getSessionInfo() {
            try {
                const session: any = await getSession()
                setSessionInfo({
                    accessToken: session.accessToken.jwtToken,
                    refreshToken: session.refreshToken.token,
                    idToken: session.idToken.jwtToken,
                })
                window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)
                window.localStorage.setItem('idTokenExpiry', `${session.idToken.payload.exp}`)
                window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
                setAuthStatus(AuthStatus.SignedIn)
            } catch (err) {
                setAuthStatus(AuthStatus.SignedOut)
            }
        }
        getSessionInfo()
    }, [setAuthStatus, authStatus])

    useEffect(() => {
        const checkRefreshToken = async () => {
            if (checkExpiry()) {
                const session: any = await refreshToken()
                window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)
                window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
                // put the id token expiry in local storage
                window.localStorage.setItem('idTokenExpiry', `${session.idToken.payload.exp}`)
            }
            setTimeout(checkRefreshToken, 1000 * 60 * 30)
            // checks every 30 minutes
        }

        checkRefreshToken()
    }, [])

    if (authStatus === AuthStatus.Loading) {
        return null
    }

    const signIn = async (email: string, password: string) => {
        try {
            const session: any = await cognito.signInWithEmail(email, password)
            window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)
            window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
            // put the id token expiry in local storage
            window.localStorage.setItem('idTokenExpiry', `${session.idToken.payload.exp}`)
            setAuthStatus(AuthStatus.SignedIn)
        } catch (err) {
            throw err
        }
    }

    async function signUp(email: string, password: string, name: string, surname: string, phone: string) {
        try {
            await cognito.signUpUserWithEmail(email, password, name, surname, phone)
        } catch (err) {
            throw err
        }
    }

    const signOut = async () => {
        try {
            cognito.signOut()
            setSessionInfo({})
            setAuthStatus(AuthStatus.SignedOut)
        } catch (error) {
            console.log('error signing out: ', error)
        }
    }

    const verifyCode = async (email: string, code: string) => {
        try {
            await cognito.verifyCode(email, code)
            setAuthStatus(AuthStatus.SignedIn)
        } catch (error) {
            console.log('error verifying code: ', error)
        }
    }

    async function getSession() {
        try {
            const session = await cognito.getSession()
            return session
        } catch (err) {
            throw err
        }
    }

    async function refreshToken() {
        try {
            const session = await cognito.refreshToken()
            return session
        } catch (err) {
            throw err
        }
    }

    async function sendCode(username: string) {
        try {
            await cognito.sendCode(username)
        } catch (err) {
            throw err
        }
    }

    async function forgotPassword(username: string, code: string, password: string) {
        try {
            await cognito.forgotPassword(username, code, password)
        } catch (err) {
            throw err
        }
    }

    async function changePassword(oldPassword: string, newPassword: string) {
        try {
            await cognito.changePassword(oldPassword, newPassword)
        } catch (err) {
            throw err
        }
    }

    const state: IAuthContext = {
        sessionInfo,
        authStatus,
        signIn,
        signUp,
        signOut,
        verifyCode,
        getSession,
        sendCode,
        forgotPassword,
        changePassword,
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
