import React, { useState, useEffect } from 'react'
import { Table, Button, Space, Typography, Row, Col, Popconfirm, TableProps } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { faSailboat, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { GraphQLClient } from 'graphql-request'
import moment from 'moment'
import * as Queries from '../libs/queries'
import * as Mutations from '../libs/mutations'
import { SortOrder } from 'antd/es/table/interface'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

interface Props {}

type ApplicationType = {
    id: string
    name: string
    period: string
    state: string
    createdAt: string
}

// TODO: Apply enum to state
// enum StateType {
//     CREATED,
//     APPROVED,
//     REVISION_REQUIRED,
// }

const Applications: React.FC<Props> = () => {
    const navigate = useNavigate()
    const [isVisible, setVisibility] = useState(true)
    const [applicationsData, setApplicationsData] = useState<ApplicationType[]>()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.AdminApplicationsTableQuery)
                const data: ApplicationType[] = response.adminGetAllApplications.map((application: any) => {
                    const registeration = response.adminGetAllRegisterations.find((registeration: any) => registeration.id == application.id)
                    let appState = ''
                    switch (true) {
                        // check registerations first, if no registeration, switch case block will go to applications
                        case registeration?.state === 'CREATED':
                            appState = 'Kayıt Onay Bekliyor'
                            break
                        case registeration?.state === 'APPROVED':
                            appState = 'Kayıt Onaylandı'
                            break
                        case registeration?.state === 'REJECTED':
                            appState = 'Kayıt Onaylanmadı'
                            break

                        case application.state === 'CREATED':
                            appState = 'Onay Bekliyor'
                            break
                        case application.state === 'APPROVED':
                            appState = 'Başvuru Onaylandı'
                            break
                        case application.state === 'REJECTED':
                            appState = 'Başvuru Onaylanmadı'
                            break
                        default:
                            appState = 'Onay Bekliyor'
                            break
                        // TODO: extend
                    }
                    let appType = ''
                    switch (application.state) {
                        case 'CREATED':
                            appType = 'Yelken Kampı Başvurusu'
                            break
                        case 'APPROVED':
                            appType = 'Yelken Kampı Kayıdı'
                            break
                        default:
                            appType = 'Yelken Kampı Başvurusu'
                        // TODO: extend
                    }

                    return {
                        id: application.id,
                        name: application.name + ' ' + application.surname,
                        period: application.period,
                        state: appState,
                        createdAt: application.createdAt,
                        // type: appType,
                    }
                })

                data.sort((a, b) => {
                    return moment(b.createdAt, 'DD.MM.YYYY - HH:mm').unix() - moment(a.createdAt, 'DD.MM.YYYY - HH:mm').unix()
                })

                setApplicationsData(data)
            } catch (e: any) {
                console.log(e.message)
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const handleDeleteApplication = async (id: string) => {
        try {
            client.setHeader('authorization', localStorage.getItem('idToken')!)
            const response = await client.request(Mutations.AdminDeleteApplication, {
                ids: [id],
            })
            if (response) {
                setApplicationsData(applicationsData?.filter((obj) => obj.id !== id))
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }

    // const handleEdit = async (id: string) => {
    //     navigate(`/update/${id}`)
    // }

    const handleEdit = (id: string) => {
        navigate(`/update/${id}`, { state: { formType: 'application' } })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibility(!isVisible)
        }, 500)
        return () => {
            clearInterval(intervalId)
        }
    }, [isVisible])

    const columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Dönem',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'İşlem Durumu',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Başvuru Tarihi',
            dataIndex: 'createdAt',
            sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder, 'ascend' as SortOrder],
            sorter: (a: ApplicationType, b: ApplicationType) => moment(a.createdAt, 'DD.MM.YYYY - HH:mm').unix() - moment(b.createdAt, 'DD.MM.YYYY - HH:mm').unix(),
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (record: any) => (
                <Space size="middle">
                    <Button style={{ background: '#273D63' }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record.id)} />
                    <Popconfirm title={<p>Başvuruyu silmek istediğine emin misin?</p>} onConfirm={() => handleDeleteApplication(record.id)} okText="Evet" cancelText="Hayır">
                        <Button type="dashed" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const tableChangeHandler: TableProps<ApplicationType>['onChange'] = (sorter) => {
        console.log('params', sorter)
    }

    let locale = {
        emptyText: 'Kayıtlı başvurunuz bulunmamaktadır',
    }

    return (
        <>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 20 }}
                    lg={{ span: 20 }}
                    md={{ span: 22 }}
                    sm={{ span: 22 }}
                    xs={{ span: 22 }}>
                    <Typography.Title
                        style={{
                            color: '#414445',
                            position: 'relative',
                            textAlign: 'center',
                            marginTop: screenWidth < 1000 ? '1rem' : '1rem',
                            marginBottom: 40,
                            fontWeight: 'bold',
                        }}>
                        GSA Yönetim Paneli - Başvuru
                    </Typography.Title>

                    <div style={{ marginBottom: '10rem', textAlign: 'left', position: 'relative', marginInline: screenWidth < 400 ? '' : '4%' }}>
                        <Table
                            showSorterTooltip={false}
                            style={{ alignSelf: 'center', marginTop: '30px', maxWidth: '100%', overflowX: 'scroll' }}
                            size={screenWidth < 400 ? 'small' : 'middle'}
                            locale={locale}
                            columns={columns}
                            dataSource={applicationsData}
                            onChange={tableChangeHandler}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Applications
