import React, { useState, useEffect } from 'react'
import { Table, Button, Space, Typography, Row, Col, Popconfirm, TableProps, Modal, Tree } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { faSailboat, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { GraphQLClient } from 'graphql-request'
import moment from 'moment'
import * as Queries from '../libs/queries'
import * as Mutations from '../libs/mutations'
import { SortOrder } from 'antd/es/table/interface'
import type { DataNode } from 'antd/es/tree'
import ExcelJS from 'exceljs'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

interface Props {}

type RegisterationType = {
    id: string
    name: string
    period: string
    state: string
    createdAt: string
}

// TODO: Apply enum to state
// enum StateType {
//     CREATED,
//     APPROVED,
//     REVISION_REQUIRED,
// }

const Registerations: React.FC<Props> = () => {
    const navigate = useNavigate()
    const [isVisible, setVisibility] = useState(true)
    const [registerationsData, setRegisterationsData] = useState<RegisterationType[]>()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [openExcelExportModal, setOpenExcelExportModal] = useState(false)

    // excel export modal
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['0-0-0', '0-0-1'])
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(['0-0-0'])
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)

    const onExpand = (expandedKeysValue: React.Key[]) => {
        console.log('onExpand', expandedKeysValue)
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpandedKeys(expandedKeysValue)
        setAutoExpandParent(false)
    }

    const onCheck = (checkedKeys: any, info: any) => {
        console.log('Checked keys:', checkedKeys)
        console.log('Check info:', info)

        // Perform any necessary operations based on the checked keys

        // Update the state with the new checked keys
        setCheckedKeys(checkedKeys)
    }

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        console.log('onSelect', info)
        setSelectedKeys(selectedKeysValue)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.AdminRegistrationsTableQuery)
                const data: RegisterationType[] = response.adminGetAllRegisterations.map((registeration: any) => {
                    let appState = ''
                    switch (true) {
                        // check registerations first, if no registeration, switch case block will go to registerations
                        case registeration?.state === 'CREATED':
                            appState = 'Kayıt Onay Bekliyor'
                            break
                        case registeration?.state === 'APPROVED':
                            appState = 'Kayıt Onaylandı'
                            break
                        case registeration?.state === 'REJECTED':
                            appState = 'Kayıt Onaylanmadı'
                            break

                        case registeration.state === 'CREATED':
                            appState = 'Onay Bekliyor'
                            break
                        case registeration.state === 'APPROVED':
                            appState = 'Başvuru Onaylandı'
                            break
                        case registeration.state === 'REJECTED':
                            appState = 'Başvuru Onaylanmadı'
                            break
                        default:
                            appState = 'Onay Bekliyor'
                            break
                        // TODO: extend
                    }
                    let appType = ''
                    switch (registeration.state) {
                        case 'CREATED':
                            appType = 'Yelken Kampı Başvurusu'
                            break
                        case 'APPROVED':
                            appType = 'Yelken Kampı Kayıdı'
                            break
                        default:
                            appType = 'Yelken Kampı Başvurusu'
                        // TODO: extend
                    }

                    return {
                        id: registeration.id,
                        name: registeration.name + ' ' + registeration.surname,
                        period: registeration.period,
                        state: appState,
                        type: appType,
                        createdAt: registeration.createdAt,
                    }
                })
                // sort it by reverse createdAt
                data.sort((a, b) => {
                    return moment(b.createdAt, 'DD.MM.YYYY - HH:mm').unix() - moment(a.createdAt, 'DD.MM.YYYY - HH:mm').unix()
                })

                setRegisterationsData(data)
            } catch (e: any) {
                console.log(e.message)
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const handleDeleteRegisteration = async (id: string) => {
        try {
            client.setHeader('authorization', localStorage.getItem('idToken')!)
            const response = await client.request(Mutations.AdminDeleteRegisteration, {
                ids: [id],
            })
            if (response) {
                setRegisterationsData(registerationsData?.filter((obj) => obj.id !== id))
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }

    // const handleEdit = async (id: string) => {
    //     navigate(`/update/${id}`)
    // }

    const handleEdit = (id: string) => {
        navigate(`/update/${id}`, { state: { formType: 'registeration' } })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibility(!isVisible)
        }, 500)
        return () => {
            clearInterval(intervalId)
        }
    }, [isVisible])

    const excelExportSelectionTreeData: DataNode[] = [
        {
            title: 'Dönem Bilgileri',
            key: 'periodInfo',
            children: [
                {
                    title: 'Katılacağı Dönem',
                    key: 'period',
                },
                {
                    title: 'Katılım Durumu',
                    key: 'firstOrAgain',
                },
                {
                    title: 'Konaklama Tipi',
                    key: 'boardingOrDay',
                },
                {
                    title: 'Eğitim Sınıfı',
                    key: 'classType',
                },
            ],
        },
        {
            title: 'Öğrenci Bilgileri',
            key: 'studentInfo',
            children: [
                {
                    title: 'Adı',
                    key: 'name',
                },
                {
                    title: 'Soyadı',
                    key: 'surname',
                },
                {
                    title: 'Cinsiyeti',
                    key: 'sex',
                },
                {
                    title: 'T.C. Kimlik Numarası',
                    key: 'citizenId',
                },
                {
                    title: 'Doğum Tarihi',
                    key: 'birthDate',
                },
                {
                    title: 'Doğum Yeri',
                    key: 'birthPlace',
                },
                {
                    title: 'Okulu',
                    key: 'school',
                },
                {
                    title: 'Sınıfı',
                    key: 'grade',
                },
                {
                    title: 'Şehir',
                    key: 'city',
                },
            ],
        },
        {
            title: 'İletişim Bilgileri',
            key: 'contactInfo',
            children: [
                {
                    title: 'Adres',
                    key: 'address',
                },
                {
                    title: 'Ev Telefonu',
                    key: 'homePhone',
                },
                {
                    title: 'Öğrenci Telefonu',
                    key: 'studentPhone',
                },
                {
                    title: 'Öğrenci E-posta Adresi',
                    key: 'studentEmail',
                },
                {
                    title: 'Anne Adı Soyadı',
                    key: 'motherNameSurname',
                },
                {
                    title: 'Baba Adı Soyadı',
                    key: 'fatherNameSurname',
                },
            ],
        },
        {
            title: 'Sağlık Bilgileri',
            key: 'healthInfo',
            children: [
                {
                    title: 'Kan Grubu',
                    key: 'bloodType',
                },
                {
                    title: 'Boy',
                    key: 'height',
                },
                {
                    title: 'Kilo',
                    key: 'weight',
                },
                {
                    title: 'Öğrenci Alerjisi',
                    key: 'studentAllergy',
                },
                {
                    title: 'Öğrenciye Alerji Yapan Yiyecekler',
                    key: 'studentAllergicFoods',
                },
                {
                    title: 'Öğrenci Sağlık Sorunları',
                    key: 'studentHealthIssues',
                },
                {
                    title: 'Öğrencinin Kullandığı İlaçlar',
                    key: 'studentUseOfMedicine',
                },
                {
                    title: 'İlaçların Yan Etkisi',
                    key: 'studentSideEffectMedicine',
                },
                {
                    title: 'Ebeveynler Evli mi?',
                    key: 'parentsMarried',
                },
                {
                    title: 'İlk Defa Uzakta Kalacak mı?',
                    key: 'firstTimeAway',
                },
                {
                    title: 'Gece İdrar Kaçırma Problemi',
                    key: 'bedwettingProblem',
                },
                {
                    title: 'Gece Uykuda Yürüme Problemi',
                    key: 'sleepwalkingProblem',
                },
            ],
        },
        {
            title: 'Spor ve Deniz Bilgileri',
            key: 'sportAndSeaInfo',
            children: [
                {
                    title: 'Spor Lisansı Var mı?',
                    key: 'doesHaveASportLicence',
                },
                {
                    title: 'Hangi Spor?',
                    key: 'whichSport',
                },
                {
                    title: 'Daha Önce Yaz Kampına Katıldı mı?',
                    key: 'haveEverJoinedASummerCampBefore',
                },
                {
                    title: 'Kamp Nerede Yapıldı?',
                    key: 'whereWasCamp',
                },
                {
                    title: 'Deniz Korkusu Var mı?',
                    key: 'doesHaveFearOfSea',
                },
                {
                    title: 'Hiç Tekneye Bindi mi?',
                    key: 'haveEverRideABoat',
                },
                {
                    title: 'Yüzme Derecesi',
                    key: 'swimmingDegree',
                },
                {
                    title: 'Optimistte Hangi Derecede?',
                    key: 'ifOptimistWhereWhatDegree',
                },
                {
                    title: 'Laserda Hangi Derecede?',
                    key: 'ifLaserWhereWhatDegree',
                },
            ],
        },
        {
            title: 'Veli Bilgileri',
            key: 'parentInfo',
            children: [
                {
                    title: 'Veli Adı',
                    key: 'parentName',
                },
                {
                    title: 'Veli Soyadı',
                    key: 'parentSurname',
                },
                {
                    title: 'Veliyle Olan Yakınlığı',
                    key: 'parentCloseness',
                },
                {
                    title: 'Veli Mesleği',
                    key: 'parentJob',
                },
                {
                    title: 'Veli Ev Adresi',
                    key: 'parentHomeAddress',
                },
                {
                    title: 'Veli İş Adresi',
                    key: 'parentWorkAddress',
                },
                {
                    title: 'Veli Ev Telefonu',
                    key: 'parentHomePhone',
                },
                {
                    title: 'Veli İş Telefonu',
                    key: 'parentWorkPhone',
                },
                {
                    title: 'Veli E-posta Adresi',
                    key: 'parentEmail',
                },
                {
                    title: 'Veli T.C. Kimlik Numarası',
                    key: 'parentCitizenId',
                },
                {
                    title: 'Anne Telefonu',
                    key: 'motherPhone',
                },
                {
                    title: 'Baba Telefonu',
                    key: 'fatherPhone',
                },
            ],
        },
        {
            title: 'Fatura Bilgileri',
            key: 'invoiceInfo',
            children: [
                {
                    title: 'Fatura Adı',
                    key: 'invoiceName',
                },
                {
                    title: 'Fatura Adresi',
                    key: 'invoiceAddress',
                },
                {
                    title: 'Vergi Adresi',
                    key: 'taxAddress',
                },
                {
                    title: 'Vergi Numarası',
                    key: 'taxNumber',
                },
            ],
        },
        {
            title: 'Düşünceler ve Bilgiler',
            key: 'thoughtsAndInfo',
            children: [
                {
                    title: 'Fikirler ve Düşünceler',
                    key: 'ideasAndThoughts',
                },
                {
                    title: 'Öğrenci Hakkında Önemli Bilgiler',
                    key: 'importantInfoAboutStudent',
                },
            ],
        },
    ]

    const columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Dönem',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'İşlem Durumu',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Başvuru Tarihi',
            dataIndex: 'createdAt',
            sortDirections: ['ascend' as SortOrder, 'descend' as SortOrder, 'ascend' as SortOrder],
            sorter: (a: RegisterationType, b: RegisterationType) => moment(a.createdAt, 'DD.MM.YYYY - HH:mm').unix() - moment(b.createdAt, 'DD.MM.YYYY - HH:mm').unix(),
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (record: any) => (
                <Space size="middle">
                    <Button style={{ background: '#273D63' }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record.id)} />
                    <Popconfirm title={<p>Başvuruyu silmek istediğine emin misin?</p>} onConfirm={() => handleDeleteRegisteration(record.id)} okText="Evet" cancelText="Hayır">
                        <Button type="dashed" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const tableChangeHandler: TableProps<RegisterationType>['onChange'] = (sorter) => {
        console.log('params', sorter)
    }

    const handleExport = async () => {
        client.setHeader('authorization', localStorage.getItem('idToken')!)
        const response = await client.request(Queries.AdminGetRegistrationsForExport)
        const data: RegisterationType[] = response.adminGetAllRegisterations
        console.log(data)

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Kayıt')

        const excelColumns: any[] = []
        excelExportSelectionTreeData.filter((item) => {
            return item.children!.filter((child: any) => {
                if (checkedKeys.includes(child.key)) {
                    excelColumns.push({
                        header: child.title,
                        key: child.key,
                        width: child.title.length * 1.2 > 20 ? child.title.length * 1.2 : 25,
                    })
                }
            })
        })

        console.log(excelColumns)

        worksheet.columns = excelColumns

        data!.forEach((registration: any) => {
            let rowValues: any = {}
            checkedKeys.forEach((checkedKey) => {
                if (
                    checkedKey === 'parentsMarried' ||
                    checkedKey === 'firstTimeAway' ||
                    checkedKey === 'bedwettingProblem' ||
                    checkedKey === 'sleepwalkingProblem' ||
                    checkedKey === 'doesHaveASportLicence' ||
                    checkedKey === 'haveEverJoinedASummerCampBefore' ||
                    checkedKey === 'doesHaveFearOfSea'
                ) {
                    console.log(registration[checkedKey])
                    rowValues[checkedKey] = registration[checkedKey] == '1' ? 'Evet' : 'Hayır'
                } else if (checkedKey === 'haveEverRideABoat') {
                    registration[checkedKey] == 'bigBoat' ? (rowValues[checkedKey] = 'Daha Büyük Tekneye Bindi') : (rowValues[checkedKey] = 'Hiç Tekneye Binmedi')
                } else if (checkedKey === 'swimmingDegree') {
                    switch (registration[checkedKey]) {
                        case 'cantSwim':
                            rowValues[checkedKey] = 'Hiç yüzemiyor'
                            break
                        case 'swimsWithLifeJacket':
                            rowValues[checkedKey] = 'Kolluk Can Yeleği İle Yüzüyor'
                            break
                        case 'swimsButCantDiveHead':
                            rowValues[checkedKey] = 'Yüzüyor ama kafasını suya sokmaktan korkuyor'
                            break
                        case 'canSwim':
                            rowValues[checkedKey] = 'Yüzüyor, dalıyor, kafasını suya sokmaktan korkmuyor'
                            break
                    }
                } else if (checkedKey === 'boardingOrDay') {
                    registration[checkedKey] == 'boarding' ? (rowValues[checkedKey] = 'Yatılı') : (rowValues[checkedKey] = 'Gündüzlü')
                } else if (checkedKey === 'classType') {
                    registration[checkedKey] == 'surf' ? (rowValues[checkedKey] = 'Yelken') : (rowValues[checkedKey] = 'Rüzgar Sörfü')
                } else if (checkedKey === 'firstOrAgain') {
                    registration[checkedKey] == 'experienced' ? (rowValues[checkedKey] = 'Tekrar') : (rowValues[checkedKey] = 'İlk Kez')
                } else if (checkedKey === 'sex') {
                    registration[checkedKey] == 'male' ? (rowValues[checkedKey] = 'Erkek') : (rowValues[checkedKey] = 'Kız')
                } else {
                    rowValues[checkedKey] = registration[checkedKey]
                }
            })
            worksheet.addRow(rowValues)
        })

        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            // Create a download link and trigger the download
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Kayıt-export.xlsx'
            link.click()

            // Clean up the object URL
            URL.revokeObjectURL(link.href)
        })
    }

    let locale = {
        emptyText: 'Kayıtlı başvurunuz bulunmamaktadır',
    }

    return (
        <>
            <Modal
                title="Excel tablosu oluşturmak için sütunları seçiniz"
                centered
                open={openExcelExportModal}
                onOk={handleExport}
                onCancel={() => setOpenExcelExportModal(false)}
                width={1000}
                footer={[
                    <Button key="back" onClick={() => setOpenExcelExportModal(false)}>
                        Cancel
                    </Button>,
                    <Button style={{ background: '#273D63' }} key="submit" type="primary" onClick={handleExport}>
                        Export
                    </Button>,
                ]}>
                <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    treeData={excelExportSelectionTreeData}
                />
            </Modal>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 20 }}
                    lg={{ span: 20 }}
                    md={{ span: 22 }}
                    sm={{ span: 22 }}
                    xs={{ span: 22 }}>
                    <Typography.Title
                        style={{
                            color: '#414445',
                            position: 'relative',
                            textAlign: 'center',
                            marginTop: screenWidth < 1000 ? '1rem' : '1rem',
                            marginBottom: 40,
                            fontWeight: 'bold',
                        }}>
                        GSA Yönetim Paneli - Kayıt
                    </Typography.Title>

                    <div style={{ marginBottom: '10rem', textAlign: 'left', position: 'relative', marginInline: screenWidth < 400 ? '' : '4%' }}>
                        <Button
                            icon={<ExportOutlined />}
                            onClick={() => setOpenExcelExportModal(true)}
                            style={{ background: '#273D63', float: 'right', marginRight: '16px', margin: '16px', color: 'white' }}>
                            Export Excel
                        </Button>
                        <Table
                            showSorterTooltip={false}
                            style={{ alignSelf: 'center', marginTop: '30px', maxWidth: '100%', overflowX: 'scroll' }}
                            size={screenWidth < 400 ? 'small' : 'middle'}
                            locale={locale}
                            columns={columns}
                            dataSource={registerationsData}
                            onChange={tableChangeHandler}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Registerations
