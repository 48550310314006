import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Radio, DatePicker, Button, Space, Row, Col, Typography, Divider, Modal, Popconfirm, Checkbox } from 'antd'
import { GraphQLClient } from 'graphql-request'
import 'dayjs/locale/zh-cn'
import type { RadioChangeEvent, DatePickerProps } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import * as Mutations from '../libs/mutations'
import * as Queries from '../libs/queries'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/tr_TR'
import 'dayjs/locale/tr'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

interface Props {
    id: string
}

interface IForm {
    state: string
    period: string
    boardingOrDay: string
    classType: string
    firstOrAgain: string

    name: string
    surname: string
    sex: string
    citizenId: string
    birthDate: string
    birthPlace: string

    school: string
    grade: string
    city: string

    address: string
    homePhone: string
    studentPhone: string
    studentEmail: string
    motherNameSurname: string
    fatherNameSurname: string

    bloodType: string
    height: string
    weight: string
    studentAllergy: string
    studentAllergicFoods: string
    studentHealthIssues: string
    studentUseOfMedicine: string
    studentSideEffectMedicine: string
    parentsMarried: string
    firstTimeAway: string
    bedwettingProblem: string
    sleepwalkingProblem: string

    doesHaveASportLicence: string
    whichSport: string
    haveEverJoinedASummerCampBefore: string
    whereWasCamp: string
    doesHaveFearOfSea: string
    haveEverRideABoat: string
    swimmingDegree: string
    ifOptimistWhereWhatDegree: string
    ifLaserWhereWhatDegree: string

    parentName: string
    parentSurname: string
    parentCloseness: string
    parentCitizenId: string
    parentBirthDate: string
    signPhoneNumber: string
    parentJob: string
    parentHomeAddress: string
    parentWorkAddress: string
    parentHomePhone: string
    parentWorkPhone: string
    parentEmail: string
    motherPhone: string
    fatherPhone: string

    invoiceName: string
    invoiceAddress: string
    taxAddress: string
    taxNumber: string

    ideasAndThoughts: string
    importantInfoAboutStudent: string

    hasparticipatedBefore: string
    createdAt: string

    priceToPay: string
}

const RegisterationForm: React.FC<Props> = (props: Props) => {
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [isExperience, setIsExperience] = useState(false)
    const [isFormValid, setIsFormValid] = useState(true)
    const [mespactErrorMessage, setMespactErrorMessage] = useState<string>()
    const [mespactColorStyle, setMespactColorStyle] = useState<'success' | 'danger'>()
    const [isMespactResponse, setIsMespactResponse] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [registerationsData, setRegisterationsData] = useState<IForm>()

    const [verifyCitizenship, setVerifyCitizenship] = useState(true)

    const navigate = useNavigate()

    // let params = useParams()
    // const id = params.id

    const { id } = props

    const [sendableToSign, setSendableToSign] = useState(false)
    const [formData, setFormData] = useState<IForm>({
        createdAt: '',
        state: '',
        period: '',
        boardingOrDay: '',
        classType: '',
        firstOrAgain: '',

        //
        name: '',
        surname: '',
        sex: '',
        citizenId: '',
        birthDate: '',
        birthPlace: '',

        //school info
        school: '',
        grade: '',
        city: '',

        //contanct info
        address: '',
        homePhone: '',
        studentPhone: '',
        studentEmail: '',
        motherNameSurname: '',
        fatherNameSurname: '',

        //Health Info
        bloodType: '',
        height: '',
        weight: '',
        studentAllergy: '',
        studentAllergicFoods: '',
        studentHealthIssues: '',
        studentUseOfMedicine: '',
        studentSideEffectMedicine: '',
        parentsMarried: '',
        firstTimeAway: '',
        bedwettingProblem: '',
        sleepwalkingProblem: '',

        //Sport and Sea Experience
        doesHaveASportLicence: '',
        whichSport: '',
        haveEverJoinedASummerCampBefore: '',
        whereWasCamp: '',
        doesHaveFearOfSea: '',
        haveEverRideABoat: '',
        swimmingDegree: '',
        ifOptimistWhereWhatDegree: '',
        ifLaserWhereWhatDegree: '',

        //Parent Info
        parentName: '',
        parentSurname: '',
        parentCloseness: '',
        parentCitizenId: '',
        parentBirthDate: '',
        signPhoneNumber: '',
        parentJob: '',
        parentHomeAddress: '',
        parentWorkAddress: '',
        parentHomePhone: '',
        parentWorkPhone: '',
        parentEmail: '',
        motherPhone: '',
        fatherPhone: '',

        //Invoice Info
        invoiceName: '',
        invoiceAddress: '',
        taxAddress: '',
        taxNumber: '',

        //Ideas and Thougts Info
        ideasAndThoughts: '',
        importantInfoAboutStudent: '',

        hasparticipatedBefore: '',

        priceToPay: '',
    })

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setIsButtonDisabled(true)
            client.setHeader('authorization', localStorage.getItem('idToken')!)
            console.log(formData)
            await client.request(Mutations.AdminUpdateRegisteration, {
                ...formData,
                id: id,
            })
            setVisiblePopup(true)
            setSendableToSign(!!formData.signPhoneNumber)
            setIsButtonDisabled(false)
            // if (visiblePopup) {
            //     navigate('/registerations')
            // }
        } catch (e: any) {
            console.log(e.message)
            throw new Error(e)
        }
    }

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFormData({ ...formData, parentBirthDate: dateString })
    }

    const handleClosePopup = () => {
        setVisiblePopup(false)
        // navigate('/registerations')
    }

    const handleStateChange = (value: string) => {
        setFormData({ ...formData, state: value })
    }

    const handlePriceChange = (value: string) => {
        setFormData({ ...formData, priceToPay: value })
    }

    const handlePeriodChange = (value: string) => {
        setFormData({ ...formData, period: value })
    }

    const handleClassTypeChange = (e: RadioChangeEvent) => {
        setFormData({ ...formData, classType: e.target.value })
    }

    const handleFirstOrAgain = (e: RadioChangeEvent) => {
        setFormData({ ...formData, firstOrAgain: e.target.value })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFormValid(true)
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.AdminGetRegisteration, {
                    ids: id,
                })
                setFormData(response.adminGetRegisteration[0])
                setSendableToSign(response.adminGetRegisteration[0].signPhoneNumber)
            } catch (e: any) {
                console.log(e.message)
                setIsFormValid(false)
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    const exportPdf = async () => {
        client.setHeader('authorization', localStorage.getItem('idToken')!)

        try {
            const createUrlResponse = await client.request(Mutations.AdminCreateRegistrationPdfUrl, {
                id,
            })
            // Fetch the file at the presigned URL
            const blobResponse = await fetch(createUrlResponse.adminCreateRegistrationPdfUrl)
            const blob = await blobResponse.blob()

            // Create a URL for the Blob and initiate a download
            const url = window.URL.createObjectURL(blob)
            const pdfElement = document.createElement('a')
            pdfElement.href = url
            pdfElement.download = `${id}.pdf`
            document.body.appendChild(pdfElement)
            pdfElement.click()
            pdfElement.remove()
        } catch (error) {
            console.error(error)
        }
    }

    const sendToSign = async () => {
        setIsMespactResponse(false)
        client.setHeader('authorization', localStorage.getItem('idToken')!)

        // Send to sign trigger mutation
        const response = await client.request(Mutations.AdminTriggerSignContract, {
            registrationId: id,
            verifyCitizenship,
        })
        if (response) {
            setIsMespactResponse(true)
            if (response.adminTriggerSignContract === 'true') {
                setMespactColorStyle('success')
                setMespactErrorMessage('Sözleşme imzalanmaya gönderildi.')
            } else {
                setMespactColorStyle('danger')
                setMespactErrorMessage(response.adminTriggerSignContract)
            }
        }
    }

    const errorJsx = (
        <>
            <Row>
                <Typography.Text type="danger" strong>
                    Bu formda doldurulabilir tüm alanlar zorunludur.
                </Typography.Text>
            </Row>
        </>
    )

    const sendToSignErrorJsx = (
        <>
            <Row justify={'end'}>
                <Typography.Text type={mespactColorStyle} strong>
                    {mespactErrorMessage}
                </Typography.Text>
            </Row>
        </>
    )

    const maxExperienceLength = 500

    return (
        <div>
            <Typography
                style={
                    screenWidth < 600
                        ? {
                              marginInline: '20px',
                          }
                        : screenWidth < 1000
                        ? {
                              marginInline: '70px',
                          }
                        : {
                              marginInline: '250px',
                          }
                }></Typography>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 15 }}
                    lg={{ span: 18 }}
                    md={{ span: 20 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}>
                    <Form onFinish={handleSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} labelAlign={'right'}>
                        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Col>
                                <Row>Vatandaşlık doğrulama Servisini</Row>
                                <Row>
                                    <Radio.Group
                                        value={verifyCitizenship}
                                        onChange={(e: RadioChangeEvent) => {
                                            setVerifyCitizenship(e.target.value)
                                        }}>
                                        <Radio value={true}>Kullan</Radio>
                                        <Radio value={false}>Kullanma</Radio>
                                    </Radio.Group>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Popconfirm
                                disabled={!sendableToSign}
                                title={<p>Sözleşmeyi imzaya göndermek istediğinize emin misiniz?</p>}
                                onConfirm={() => sendToSign()}
                                okText="Evet"
                                okButtonProps={{
                                    style: {
                                        background: '#273D63',
                                        color: '#ffffff',
                                    },
                                }}
                                cancelText="Hayır">
                                <Button
                                    disabled={!sendableToSign}
                                    style={{ border: '1px solid #273D63', background: '#273D63', color: '#ffffff', marginTop: '20px', marginBottom: '40px', marginRight: '30px' }}>
                                    {!sendableToSign ? 'Sözleşme: kamp ücreti ve imza telefon numarası girilmeli' : 'Sözleşmeyi İmzaya Gönder'}
                                </Button>
                            </Popconfirm>
                            {/* 
                            <Button onClick={exportPdf} style={{ border: '1px solid #273D63', background: '#273D63', color: '#ffffff', marginTop: '20px', marginBottom: '40px' }}>
                                PDF Export
                            </Button> */}
                        </Row>
                        {!isFormValid ? errorJsx : null}
                        {isMespactResponse ? sendToSignErrorJsx : null}
                        <Row justify={'center'} style={{ background: '#273D63', padding: '40px', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold', color: 'white' }}>Başvuru durumu</div>
                                </Row>
                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item style={{ fontWeight: 'bold', color: 'black' }}>
                                        <Select
                                            style={{ minWidth: 327 }}
                                            value={formData.state}
                                            onChange={handleStateChange}
                                            options={[
                                                { label: 'Onay bekliyor', value: 'CREATED' },
                                                { label: 'Başvuru onaylandı', value: 'APPROVED' },
                                                { label: 'Başvuru Onaylanmadı', value: 'REJECTED' },
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col span={2}></Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <div style={{ justifyContent: 'center', fontWeight: 'bold', color: 'white' }}>Başvuru tarihi</div>
                                </Row>
                                <Input type="text" name="createdAt" value={formData.createdAt} disabled style={{ background: 'white', color: 'black' }} />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold', color: 'white' }}>Kayıt Ücreti</div>
                                </Row>
                                <Input
                                    type="text"
                                    name="priceToPay"
                                    value={formData.priceToPay}
                                    onChange={(event) => handlePriceChange(event.target.value)} // Assign the handlePriceChange function to onChange
                                    style={{ background: 'white', color: 'black' }}
                                />
                            </Col>
                            <Col span={2}></Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 10 }}></Col>
                        </Row>
                        <Form.Item>
                            {/* TODO: height kötü oldu, düzeltilecek */}
                            <Row style={{ justifyContent: 'center', marginTop: '20px', height: '60px' }}>
                                <Space size={'small'}>
                                    <Button
                                        disabled={isButtonDisabled}
                                        htmlType="submit"
                                        style={{ border: '1px solid #273D63', background: '#ffffff', color: '#273D63', marginTop: '20px', marginBottom: '40px' }}>
                                        Güncelle
                                    </Button>
                                    <Modal visible={visiblePopup} footer={null} onCancel={handleClosePopup}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography.Title level={4}>Global Sailing Academy Online Kayıt Sistemi</Typography.Title>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography>
                                                Başvuru güncellendi. <br />
                                            </Typography>
                                        </div>
                                    </Modal>
                                    <Popconfirm
                                        title={<p>Güncellemeyi iptal etmek istediğinize emin misiniz?</p>}
                                        onConfirm={() => navigate('/applications')}
                                        okText="Evet"
                                        okButtonProps={{
                                            style: {
                                                background: '#273D63',
                                                color: '#ffffff',
                                            },
                                        }}
                                        cancelText="Hayır">
                                        <Button style={{ border: '1px solid #273D63', background: '#273D63', color: '#ffffff', marginTop: '20px', marginBottom: '40px' }}>
                                            İptal
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Row>
                        </Form.Item>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            DÖNEM BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ pull: 1, span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Katılacağı Dönem</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item style={{ fontWeight: 'bold' }}>
                                        <Select
                                            style={{ minWidth: 327 }}
                                            value={formData.period}
                                            onChange={handlePeriodChange}
                                            options={[
                                                {
                                                    label: <span style={{ fontSize: 13, fontWeight: 'bold', color: '#414445' }}> Yatılı Kamp</span>,
                                                    options: [
                                                        { label: '30 Haziran - 9 Temmuz', value: '30 Haziran - 9 Temmuz' },
                                                        { label: '13 Temmuz - 22 Temmuz', value: '13 Temmuz - 22 Temmuz' },
                                                        { label: '26 Temmuz - 4 Ağustos', value: '26 Temmuz - 4 Ağustos' },
                                                        { label: '15 Ağustos - 24 Ağustos', value: '15 Ağustos - 24 Ağustos' },
                                                    ],
                                                },
                                                {
                                                    label: <span style={{ fontSize: 13, fontWeight: 'bold', color: '#414445' }}>Gündüzlü Kamp</span>,
                                                    options: [
                                                        { label: '24 Haziran - 28 Haziran', value: '24 Haziran - 28 Haziran' },
                                                        { label: '7 Ağustos - 11 Ağustos', value: '7 Ağustos - 11 Ağustos' },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Konaklama Tipi</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item>
                                        <Radio.Group value={formData.boardingOrDay}>
                                            <Space>
                                                <Radio tabIndex={3} value={'day'}>
                                                    Gündüzlü
                                                </Radio>
                                                <Radio tabIndex={4} value={'boarding'}>
                                                    Yatılı
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Eğitim Sınıfı</div>
                                </Row>

                                <Row justify="space-around" align="top">
                                    <Form.Item style={{ fontWeight: 'bold' }}>
                                        <Radio.Group value={formData.classType} onChange={handleClassTypeChange}>
                                            <Space>
                                                <Radio style={{ fontWeight: 'normal' }} value={'sail'}>
                                                    Yelken
                                                </Radio>
                                                <Radio style={{ fontWeight: 'normal', whiteSpace: 'nowrap' }} value={'windSurf'}>
                                                    Rüzgar Sörfü
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>

                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Katılım Durumu</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item>
                                        <Radio.Group value={formData.firstOrAgain} onChange={handleFirstOrAgain}>
                                            <Space>
                                                <Radio tabIndex={5} value={'firstTime'}>
                                                    İlk
                                                </Radio>
                                                <Radio tabIndex={6} value={'experienced'}>
                                                    Tekrar
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal"></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            KİMLİK BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item rules={[{ required: true, message: 'Bu alan zorunludur.' }]} style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input tabIndex={7} type="text" name="name" value={formData.name} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input tabIndex={8} type="text" name="surname" value={formData.surname} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Tarihi">
                                    <Input value={formData.birthDate} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Cinsiyeti">
                                    <Select value={formData.sex}>
                                        <Select.Option value="male">Erkek</Select.Option>
                                        <Select.Option value="female">Kız</Select.Option>
                                        tabIndex={9}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="TC Kimlik No">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={10} type="text" name="citizenId" value={formData.citizenId} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Yeri">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={12} type="text" name="birthPlace" value={formData.birthPlace} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            OKUL BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Okulu">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={12} type="text" name="school" value={formData.school} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İli">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={14} type="text" name="city" value={formData.city} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 2 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Sınıfı">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={13} type="text" name="grade" value={formData.grade} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            İLETİŞİM BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Yaşadığı Ev Adresi">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={15} type="text" name="address" value={formData.address} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Ev Telefonu">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={16} type="text" name="homePhone" value={formData.homePhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin E-posta Adresi">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={17} type="text" name="studentEmail" value={formData.studentEmail} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Cep Telefonu">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={18} type="text" name="studentPhone" value={formData.studentPhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Anne Adı Soyadı">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={19}
                                        type="text"
                                        name="motherNameSurname"
                                        value={formData.motherNameSurname}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Baba Adı Soyadı">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={20}
                                        type="text"
                                        name="fatherNameSurname"
                                        value={formData.fatherNameSurname}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            SAĞLIK BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col span={24}>
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Kilosu">
                                            <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={21} type="text" name="weight" value={formData.weight} />
                                        </Form.Item>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Kan Grubu">
                                            <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={23} type="text" name="bloodType" value={formData.bloodType} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Boyu (cm)">
                                            <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={22} type="text" name="height" value={formData.height} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row style={{ paddingTop: '3rem' }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            style={{ fontWeight: 'bold' }}
                                            label="Alerjik olduğu veya sakıncalı yiyecekler">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={23}
                                                type="text"
                                                name="studentAllergicFoods"
                                                value={formData.studentAllergicFoods}
                                            />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Kullandığı İlaçlar*">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={24}
                                                type="text"
                                                name="studentUseOfMedicine"
                                                value={formData.studentUseOfMedicine}
                                            />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Alerjileri">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={25}
                                                type="text"
                                                name="studentAllergy"
                                                value={formData.studentAllergy}
                                            />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Sağlık Problemleri">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={26}
                                                type="text"
                                                name="studentHealthIssues"
                                                value={formData.studentHealthIssues}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            style={{ fontWeight: 'bold' }}
                                            label="Kursiyerin Kullanması Sakıncalı İlaçlar*">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={27}
                                                type="text"
                                                name="studentSideEffectMedicine"
                                                value={formData.studentSideEffectMedicine}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Anne & Baba Beraber mi?*</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.parentsMarried}>
                                                    <Space>
                                                        <Radio tabIndex={29} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={30} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Ailesinden İlk Ayrılışı mı?*</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.firstTimeAway}>
                                                    <Space>
                                                        <Radio tabIndex={31} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={32} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Yatak Islatma Problemi Var mı?*</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.bedwettingProblem}>
                                                    <Space>
                                                        <Radio tabIndex={33} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={34} value={'0'}>
                                                            Hayıt
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Uyurgezerlik Problemi Var mı?*</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.sleepwalkingProblem}>
                                                    <Space>
                                                        <Radio tabIndex={35} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={36} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            SPOR VE DENİZ GEÇMİŞİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col span={24}>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Herhangi Bir Spor Dalında Lisansı Var mı?</div>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.doesHaveASportLicence}>
                                                    <Space>
                                                        <Radio tabIndex={37} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={38} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Hangi Spor?">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={39}
                                                type="text"
                                                name="whichSport"
                                                value={formData.whichSport}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Daha Önce Herhangi Bir Yaz Kampına Katıldı mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.haveEverJoinedASummerCampBefore}>
                                                    <Space>
                                                        <Radio tabIndex={40} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={41} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Nerede?">
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={42}
                                                type="text"
                                                name="whereWasCamp"
                                                value={formData.whereWasCamp}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Deniz Korkusu Var mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.doesHaveFearOfSea}>
                                                    <Space>
                                                        <Radio tabIndex={43} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={44} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Col>
                                                <div style={{ fontWeight: 'bold' }}>Hiç Tekneye Bindi mi?</div>
                                                <Form.Item>
                                                    <Select style={{ minWidth: 280 }} value={formData.haveEverRideABoat}>
                                                        <Select.Option tabIndex={45} value="bigBoat">
                                                            Daha Büyük Tekneye Bindi
                                                        </Select.Option>
                                                        <Select.Option tabIndex={46} value="neverRide">
                                                            Hiç Tekneye Binmedi
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12, push: 1 }} md={{ span: 12, push: 1 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Col>
                                                <div style={{ fontWeight: 'bold' }}>Yüzme Bilme derecesi nedir?</div>
                                                <Form.Item>
                                                    <Select style={{ minWidth: 280 }} value={formData.swimmingDegree}>
                                                        <Select.Option tabIndex={47} value="cantSwim">
                                                            Hiç yüzemiyor
                                                        </Select.Option>
                                                        <Select.Option tabIndex={48} value="swimsWithLifeJacket">
                                                            Kolluk Can Yeleği İle Yüzüyor
                                                        </Select.Option>
                                                        <Select.Option tabIndex={49} value="swimsButCantDiveHead">
                                                            Yüzüyor ama kafasını suya sokmaktan korkuyor
                                                        </Select.Option>
                                                        <Select.Option tabIndex={50} value="canSwim">
                                                            Yüzüyor, dalıyor, kafasını suya sokmaktan korkmuyor
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" align="top">
                                    <Col>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Optimist Yaptıysa, Ne Derece / Nerede?" style={{ fontWeight: 'bold' }}>
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={51}
                                                type="text"
                                                name="ifOptimistWhereWhatDegree"
                                                value={formData.ifOptimistWhereWhatDegree}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Laser Yaptıysa, Ne Derece / Nerede?" style={{ fontWeight: 'bold' }}>
                                            <Input
                                                disabled
                                                style={{ background: 'white', color: 'black' }}
                                                tabIndex={52}
                                                type="text"
                                                name="ifLaserWhereWhatDegree"
                                                value={formData.ifLaserWhereWhatDegree}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            VELİ - VASİ BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 1 }} lg={{ span: 12, pull: 1 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={53}
                                        type="text"
                                        name="parentName"
                                        value={formData.parentName}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={55}
                                        type="text"
                                        name="parentSurname"
                                        value={formData.parentSurname}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Tarihi">
                                    <DatePicker
                                        format={'DD-MM-YYYY'}
                                        locale={locale}
                                        onChange={onDateChange}
                                        value={formData.parentBirthDate ? dayjs(formData.parentBirthDate, 'DD-MM-YYYY') : null}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Anne Cep">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={57} type="text" name="motherPhone" value={formData.motherPhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Mesleği">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={59} type="text" name="parentJob" value={formData.parentJob} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İş Adresi">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={61}
                                        type="text"
                                        name="parentWorkAddress"
                                        value={formData.parentWorkAddress}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Baba Cep">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={65} type="text" name="fatherPhone" value={formData.fatherPhone} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 0 }} lg={{ span: 12, pull: 0 }} xl={{ span: 12, push: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="TC Kimlik Numarası">
                                    <Input
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={54}
                                        type="text"
                                        name="parentCitizenId"
                                        value={formData.parentCitizenId}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Yakınlık Derecesi">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={56}
                                        type="text"
                                        name="parentCloseness"
                                        value={formData.parentCloseness}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Ev Adresi">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={58}
                                        type="text"
                                        name="parentHomeAddress"
                                        value={formData.parentHomeAddress}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Ev Telefonu">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={60}
                                        type="text"
                                        name="parentHomePhone"
                                        value={formData.parentHomePhone}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İş Telefonu">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={62}
                                        type="text"
                                        name="parentWorkPhone"
                                        value={formData.parentWorkPhone}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Email">
                                    <Input
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={66}
                                        type="text"
                                        name="parentEmail"
                                        value={formData.parentEmail}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İmza Telefon (+905...)">
                                    <Input
                                        style={{ background: 'white', color: 'black' }}
                                        type="text"
                                        placeholder="+905321234545"
                                        name="signPhoneNumber"
                                        value={formData.signPhoneNumber}
                                        onChange={handleChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            FATURA BİLGİLERİ
                        </Typography.Title>
                        <Typography.Text
                            style={{
                                fontSize: 16,
                                color: 'black',
                                textAlign: 'left',
                                margin: 0,
                                marginLeft: 10,
                                marginBottom: 24,
                                fontWeight: 'bold',
                            }}>
                            **Kamp ücretinin yatırılacağı hesabın bilgilerini girmenizi rica ederiz.
                        </Typography.Text>
                        <Row style={{ marginTop: 16, background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Faturanın Kesileceği İsim *">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={67} type="text" name="invoiceName" value={formData.invoiceName} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Vergi Dairesi">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={68} type="text" name="taxAddress" value={formData.taxAddress} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adres*">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={69}
                                        type="text"
                                        name="invoiceAddress"
                                        value={formData.invoiceAddress}
                                    />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Vergi Numarası / TC Kimlik Numarası *">
                                    <Input disabled style={{ background: 'white', color: 'black' }} tabIndex={70} type="text" name="taxNumber" value={formData.taxNumber} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            FİKİR VE DÜŞÜNCELER
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Veli Fikir ve Düșünceleri *">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={71}
                                        type="text"
                                        name="ideasAndThoughts"
                                        value={formData.ideasAndThoughts}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyer Hakkında Önemli Ön Bilgiler *">
                                    <Input
                                        disabled
                                        style={{ background: 'white', color: 'black' }}
                                        tabIndex={72}
                                        type="text"
                                        name="importantInfoAboutStudent"
                                        value={formData.importantInfoAboutStudent}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal"></Divider>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default RegisterationForm
