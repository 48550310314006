import React, { useState, useEffect } from 'react'
import { Card, Image, Space, Typography, Col } from 'antd'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import gsalogo3 from '../../assets/gsa-3-logo.png'

const SharedHeader: React.FC = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])
    return (
        <Card
            style={{
                position: 'absolute',
                background: '#2b395f',
                height: '620px',
                width: '100%',
                borderRadius: 0,
                border: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
            }}>
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', border: 'none' }}>
                <a href="https://www.globalsailingacademy.com/">
                    <Image alt="Kids Surfing" preview={false} style={{ width: '200px', objectFit: 'fill' }} src={gsalogo3} />
                </a>
            </Card>
            <hr style={{ width: '200px', marginBottom: '30px' }}></hr>
            <Space size="middle" style={{ justifyContent: 'center', display: 'flex' }}>
                <a href="https://www.instagram.com/globalsailingacademy/" className="youtube social">
                    <FontAwesomeIcon icon={faInstagram} size="3x" style={{ color: 'white' }} />
                </a>
                <a href="https://www.youtube.com/channel/UCoyUN8Hcn2kUBv94CyQIBOA" className="youtube social">
                    <FontAwesomeIcon icon={faYoutube} size="3x" style={{ color: 'white' }} />
                </a>

                <a href="https://www.facebook.com/globalsailingacademy" className="facebook social">
                    <FontAwesomeIcon icon={faFacebook} size="3x" style={{ color: 'white' }} />
                </a>
            </Space>
            <div style={{ borderTop: '1px solid #595959', width: '100%', paddingTop: '15px' }}></div>
            <Typography style={{ display: 'flex', justifyContent: 'center', color: '#ffffff', fontSize: '14px Montserrat', fontFamily: 'sans-serif' }}>
                2024 © Global Sailing Academy
            </Typography>
            <Space size={screenWidth < 400 ? 4 : 'middle'} style={{ justifyContent: 'center', display: 'flex' }}>
                <Col>
                    <Link to="https://www.globalsailingacademy.com/" style={{ float: 'left', fontWeight: 'bold', color: '#ffffff' }}>
                        Anasayfa
                    </Link>
                </Col>
                <Col>
                    <Link to="https://www.globalsailingacademy.com/" style={{ float: 'left', fontWeight: 'bold', color: '#ffffff' }}>
                        Hakkımızda
                    </Link>
                </Col>
                <Col>
                    <Link to="https://www.globalsailingacademy.com/" style={{ float: 'left', fontWeight: 'bold', color: '#ffffff' }}>
                        Galeri
                    </Link>
                </Col>
                <Col>
                    <Link to="https://www.globalsailingacademy.com/" style={{ float: 'left', fontWeight: 'bold', color: '#ffffff' }}>
                        K.V.K.K
                    </Link>
                </Col>
                <Col>
                    <Link to="https://www.globalsailingacademy.com/" style={{ float: 'left', fontWeight: 'bold', color: '#ffffff' }}>
                        İletişim
                    </Link>
                </Col>
            </Space>
        </Card>
    )
}

export default SharedHeader
