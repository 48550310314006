import { Radio, RadioChangeEvent, Row } from 'antd'
import { GraphQLClient } from 'graphql-request'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Application from './routes/UpdateApplication'
import Registeration from './routes/UpdateRegisteration'
import * as Queries from './libs/queries'
import { useLocation } from 'react-router-dom'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

function FormSwitcher() {
    const location = useLocation()
    const formType = location.state?.formType

    const [isRegistered, setIsRegistered] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.CheckRegistered, {
                    ids: id,
                })
                setIsRegistered(!!response.adminGetRegisteration[0])
                setSelectedForm(formType)
            } catch (e: any) {
                console.log(e.message)
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    let params = useParams()
    const id = params.id!

    const [selectedForm, setSelectedForm] = useState<'application' | 'registeration'>(formType)

    const handleFormSelectionChange = (event: RadioChangeEvent) => {
        setSelectedForm(event.target.value as 'application' | 'registeration')
    }

    return (
        <div>
            <Row style={{ justifyContent: 'center', paddingTop: '5rem', fontWeight: 'bold' }}>
                <Radio.Group size="large" value={selectedForm} onChange={handleFormSelectionChange}>
                    <Radio.Button value="application">Başvuru Formu</Radio.Button>
                    <Radio.Button disabled={!isRegistered} value="registeration">
                        Kayıt Formu
                    </Radio.Button>
                </Radio.Group>
            </Row>
            {selectedForm === 'application' ? <Application id={id} /> : <Registeration id={id} />}
        </div>
    )
}

export default FormSwitcher
