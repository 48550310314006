import gql from 'graphql-tag'

export const AdminGetAllApplications = gql`
    query AdminGetAllApplications {
        adminGetAllApplications {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
            state
        }
    }
`

export const AdminGetApplication = gql`
    query AdminGetApplication($ids: [ID]!) {
        adminGetApplication(ids: $ids) {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
            state
            createdAt
        }
    }
`

export const AdminGetAllRegisterations = gql`
    query AdminGetAllRegisterations {
        adminGetAllRegisterations {
            id

            period
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
            state
        }
    }
`

export const AdminGetRegisteration = gql`
    query AdminGetRegisteration($ids: [ID]!) {
        adminGetRegisteration(ids: $ids) {
            id

            period
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentBirthDate
            signPhoneNumber
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
            state
            createdAt

            priceToPay
        }
    }
`

export const AdminApplicationsTableQuery = gql`
    query AdminGetAllApplications {
        adminGetAllApplications {
            id
            name
            period
            surname
            state
            createdAt
        }
        adminGetAllRegisterations {
            id
            state
        }
    }
`


export const CheckRegistered = gql`
    query AdminGetRegisteration($ids: [ID]!) {
        adminGetRegisteration(ids: $ids) {
            id
        }
    }
`


export const AdminRegistrationsTableQuery = gql`
    query AdminGetAllRegisterations {
        adminGetAllRegisterations {
            id
            name
            period
            surname
            state
            createdAt
        }
    }
`

export const AdminGetRegistrationsForExport = gql`
    query AdminGetAllRegisterations {
        adminGetAllRegisterations {
            id

            period
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
            state
            createdAt

            priceToPay
        }
    }
`