import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Button, Row, Col, Typography } from 'antd'
import { AuthContext } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { SignedOut } from '../contexts/authContext'

const { Text, Title } = Typography

const SignIn: React.FC = () => {
    const navigate = useNavigate()
    // const { signIn } = useAuth();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const authContext = useContext(AuthContext)

    const onFinish = async (values: any) => {
        try {
            setError('Loading...')
            await authContext.signIn(values.email, values.password)
        } catch (err: any) {
            setLoading(false)
            switch (err.code) {
                case 'UserNotFoundException':
                    setError('Email adresi bulunamadı.')
                    break
                case 'NotAuthorizedException':
                    setError('Şifre hatalı.')
                    break
                case 'UserNotConfirmedException':
                    setError('Mail adresinizi doğrulayın.')
                    break
                default:
                    setError(err.message)
                    break
            }
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const errorJsx = (
        <>
            <Form.Item>
                <Text type={loading ? 'success' : 'danger'}>{error}</Text>
            </Form.Item>
        </>
    )

    return (
        <SignedOut>
            <Row justify="center" align="middle">
                <Col>
                    <Title style={screenWidth < 1000 ? { textAlign: 'center', fontSize: '35px' } : { textAlign: 'center', marginTop: '10rem', fontSize: '35px' }}>Giriş Yap</Title>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        style={{
                            padding: '60px',
                            color: 'transparent',
                            backdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                            WebkitBackdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                            backgroundClip: 'padding-box',
                            border: '1px solid white',
                            borderRadius: '2rem',
                            overflow: 'hidden',
                            marginBottom: '4rem',
                        }}>
                        <Form.Item name="email" rules={[{ required: true, message: 'Email boş bırakılamaz!' }]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: 'Şifre boş bırakılamaz!' }]}>
                            <Input.Password placeholder="Şifre" />
                        </Form.Item>
                        {error ? errorJsx : null}
                        <Form.Item>
                            <Button htmlType="submit" className="login-form-button" block style={{ background: '#f39200' }}>
                                Giriş Yap
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </SignedOut>
    )
}

export default SignIn
