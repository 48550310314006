/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'eu-west-1',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_KZcLEM1C4',
    aws_user_pools_web_client_id: '2jsgu0h7m64qak8p4mclm7ee15',
    oauth: {
        domain: 'gsa-development-test.auth.eu-west-1.amazoncognito.com',
    },
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
}

export default awsmobile
