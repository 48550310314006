import React, { useState, useEffect, useContext } from 'react'
import { Layout, Space, Image, Col, Divider, Menu, Dropdown, Avatar, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone, faShip, faBookmark, faCamera, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import logoV2 from '../../assets/logo-v2.gif'

import { AuthContext } from '../../contexts/authContext'

const SharedHeader: React.FC = () => {
    const [pageType, setPageType] = useState('')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const signOutClicked = () => {
        authContext.signOut()
        console.log(authContext)
        localStorage.clear()
        navigate('/signin')
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const handleNavigateMainPage = () => {
        navigate('/applications')
    }

    const handleApplicationClicked = () => {
        navigate('/applications')
        setPageType('application')
    }

    const handleRegisterationClicked = () => {
        navigate('/registerations')
        setPageType('registeration')
    }

    const link1Style = {
        color: pageType == 'application' ? '#f39200' : '#414445',
    }
    const link2Style = {
        color: pageType == 'registeration' ? '#f39200' : '#414445',
    }

    const profileMenu = (
        <Menu>
            <Menu.Item key="2">
                <a onClick={signOutClicked}>Çıkış Yap</a>
            </Menu.Item>
        </Menu>
    )

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <a onClick={handleApplicationClicked}>Başvuru</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a onClick={handleRegisterationClicked}>Kayıt</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a onClick={signOutClicked}>Çıkış Yap</a>
            </Menu.Item>
        </Menu>
    )

    return (
        <Layout style={{ position: 'relative', width: '100%', borderBottom: '1px solid #f39200' }}>
            <Layout.Header
                style={
                    screenWidth < 400
                        ? { width: '100%', minHeight: '120px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : screenWidth < 600
                        ? { minHeight: '80px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : screenWidth < 1000
                        ? { minHeight: '80px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : { height: '40px', background: '#f39200', lineHeight: 3 }
                }>
                <Layout
                    style={
                        screenWidth < 400
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '135px', width: '125px' }
                            : screenWidth < 600
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '130px', width: '125px' }
                            : screenWidth < 1000
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '130px', width: '125px' }
                            : {
                                  position: 'absolute',
                                  background: 'transparent',
                                  zIndex: '10',
                                  marginTop: '48px',
                                  marginLeft: '3%',
                                  width: '125px',
                                  height: 'auto',
                              }
                    }>
                    {screenWidth < 1000 ? (
                        <Image onClick={handleNavigateMainPage} alt="Logo" preview={false} src="https://www.globalsailingacademy.com/wp-content/uploads/2021/10/gsa-logo.png" />
                    ) : (
                        <Layout style={{ position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '48px', marginLeft: '3%', width: '125px', height: 'auto' }}>
                            <a onClick={() => handleNavigateMainPage()}>
                                <Image alt="Flag" preview={false} src={logoV2} />
                            </a>
                        </Layout>
                    )}
                </Layout>
            </Layout.Header>
            {screenWidth < 1000 ? (
                <div
                    style={{
                        height: '170px',
                        background: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 1,
                        borderBottom: '3px solid #f39200',
                    }}>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            {/* <FontAwesomeIcon size="2x" style={{ color: '' }} icon={faBars} /> */}
                            <Image
                                width={25}
                                style={{ marginTop: '130px' }}
                                alt="hamburger-button"
                                preview={false}
                                src="https://www.globalsailingacademy.com/wp-content/uploads/2022/02/icon-menu-grey.png"
                            />
                        </a>
                    </Dropdown>
                </div>
            ) : (
                <div style={{ height: '80px', background: '#ffffff', display: 'flex', justifyContent: 'center', zIndex: 1 }}>
                    <div style={{ marginTop: '30px', marginRight: '230px', paddingBottom: '10px' }}>
                        <Typography.Link style={link1Style} onClick={handleApplicationClicked}>
                            Başvurular
                        </Typography.Link>
                        <Divider type="vertical" style={{ marginInline: '25px' }} />
                        <Typography.Link style={link2Style} onClick={handleRegisterationClicked}>
                            Kayıtlar
                        </Typography.Link>
                        <Divider type="vertical" style={{ marginInline: '25px' }} />

                        <Dropdown overlay={profileMenu}>
                            <Avatar style={{ backgroundColor: '#f39200', cursor: 'pointer', position: 'absolute', right: '50px' }} size="large">
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: '1.5em', color: 'white' }} />
                            </Avatar>
                        </Dropdown>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default SharedHeader
